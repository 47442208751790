import React, { Component } from "react";
import "./Events.sass";
import toBrake from "../lib/toBrake";

function EventItem({ title, description, thumbnail, link }) {
  return (
    <li>
      <a href={link}>
        <img src={thumbnail} alt={title} />
        <div className="event-label">
          <div className="event-title">{toBrake(title)}</div>
          <div className="event-description">{toBrake(description)}</div>
        </div>
      </a>
    </li>
  );
}

class Events extends Component {
  render() {
    const { events } = this.props;
    return (
      <ul className="events">
        {events.map((i, k) => (
          <EventItem key={k} {...i} />
        ))}
      </ul>
    );
  }
}

export default Events;
