import React from 'react';
import './Search.sass'

function Search({value, placeholder, onChange}) {
  return (
    <div className="search">
      <input type="text" placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)}/>
      <button onClick={() => onChange('')}>✕</button>
    </div>
);
}

export default Search;