import React, { Component } from "react";
import "./App.sass";
import Content from "./Content";
import Events from "./Events";
import Search from "./Search";

class App extends Component {
  state = {
    events: {
      title: "",
      search_placeholder: "",
      events: [],
    },
    page_background_color: "",
    search_term: "",
  };
  componentDidMount() {
    fetch("Events.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            events: data,
            page_background_color: data.page_background_color,
          },
          () => {
            document.title = this.state.events.title;
          }
        );
      });
  }

  handleSearch = (value) => {
    this.setState({ search_term: value });
  };

  render() {
    const { events, search_term, page_background_color } = this.state;
    const filtered_events = events.events.filter((i) => {
      return i.title.toLowerCase().indexOf(search_term.toLowerCase()) !== -1;
    });
    return (
      <div style={{ backgroundColor: page_background_color }} className="app">
        <Content>
          <Search
            value={search_term}
            placeholder={events.search_placeholder}
            onChange={this.handleSearch}
          />
          <Events events={filtered_events} />
        </Content>
      </div>
    );
  }
}

export default App;
